import { Box, TextField , CircularProgress} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridSelectionModel,
  GridToolbarContainer,
  
} from "@mui/x-data-grid";
import axios from "axios";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  GlobalStateInterface,
  useGlobalState,
} from "../components/GlobalStateProvider";
import AllowListEntry from "../types/AllowListEntry";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";



function CustomToolbarComponent(props: {
  selectionModel: GridSelectionModel;
  state: Partial<GlobalStateInterface>;
  setItems: Dispatch<SetStateAction<AllowListEntry[]>>;
  items: AllowListEntry[];
}) {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(false); // New loading state
  const showMessage = (message: string) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  
    setTimeout(() => {
      setSnackbarOpen(false);
    }, 10000);
  };
  const [inputValue, setInputValue] = useState("");
  const handleChange = (event: any) => {
    setInputValue(event.target.value);
  };

  return (
    <GridToolbarContainer
      sx={{
        margin: "20px",
        padding: "4px",
        borderBottom: "2px green solid",
      }}
    >
      <button
        style={{
          backgroundColor: "green",
          color: "white",
          padding: "10px 15px ",
          borderRadius: "25px",
          marginLeft: "15px",
          marginRight: "0",
          border: "none",
          fontFamily: "monospace",
        }}
        onClick={() =>
          addAllowListItem(new AllowListEntry(undefined, inputValue), props.state, props.setItems)
        }
      >
        Add User
      </button>
      
      <TextField
        id="basic"
        data-testid="userInput"
        style={{
          marginLeft: "15px",
          marginRight: "auto",
          marginBottom: "20px",
          width: "250px",
          height: "50px",
        }}
        onChange={handleChange}
        value={inputValue}
        label="E-Mail"
        InputProps={{
          inputProps: { maxLength: 45 },
        }}
      />

      {props.selectionModel.length > 0 && (
        <button
          style={{
            backgroundColor: "green",
            color: "white",
            padding: "10px 15px ",
            borderRadius: "25px",
            marginLeft: "auto",
            marginRight: "15px",
            border: "none",
            fontFamily: "monospace",
          }}
          onClick={() =>
            removeAllowListItem(
              props.selectionModel,
              props.state,
              props.setItems,
              props.items
            )
          }
          data-testid="deleteButton"
        >
          {`Delete ${props.selectionModel.length} User`}
        </button>
      )}

<div style={{ display: "flex", alignItems: "center", marginLeft: "15px" }}>
      <button
        style={{
          backgroundColor: "green",
          color: "white",
          padding: "10px 15px ",
          borderRadius: "25px",
          marginRight: "15px",
          border: "none",
          fontFamily: "monospace",
        }}
        onClick={() =>
          sendMails(props.state, showMessage, setLoading)
        }
      >
        Notify Tenderers
      </button>
      {loading && <CircularProgress style={{ color: 'black' }} />} { }

      <div style={{ flexGrow: 1 }} />

      {snackbarOpen && (
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={5000}
          onClose={() => setSnackbarOpen(false)}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            severity="success"
            style={{ marginRight: "15px" }}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
      )}
    </div>
    </GridToolbarContainer>
  );
}

function sendMails(
  state: Partial<GlobalStateInterface>,
  showMessage: (message: string) => void,
  setLoading: any
) {
  setLoading(true);
  const headers = {
    Authorization: `Bearer ${state.accessToken}`,
    "Content-Type": "application/json",
  };

  const data = "";
  axios
    .post(`${process.env.REACT_APP_API_URL}/api/v1/whitelist/notify`, data[0], {
      headers: headers,
    }).then(() => {
      showMessage('Mails have been sent');
    }).finally(() => setLoading(false));
}

function addAllowListItem(
  item: AllowListEntry,
  state: Partial<GlobalStateInterface>,
  setItems: Dispatch<SetStateAction<AllowListEntry[]>>
) {
  const headers = {
    Authorization: `Bearer ${state.accessToken}`,
    "Content-Type": "application/json",
  };

  const data = [{
    ffId: {
      value: null
    }, 
    username: item.username,
  }];
  axios
    .post(`${process.env.REACT_APP_API_URL}/api/v1/whitelist`, data[0], {
      headers: headers,
    })
    .then((response) => setItems(response.data.allowlist));
}

function removeAllowListItem(
  indices: GridSelectionModel,
  state: Partial<GlobalStateInterface>,
  setItems: Dispatch<SetStateAction<AllowListEntry[]>>,
  items: AllowListEntry[]
) {
  const headers = {
    Authorization: `Bearer ${state.accessToken}`,
    "Content-Type": "application/json",
  };

  const data = indices.map((e) => {
    return items[Number(e)];
  });
  axios
    .delete(`${process.env.REACT_APP_API_URL}/api/v1/whitelist/`, {
      headers: headers,
      data: data,
    })
    .then((response) => {
      setItems(response.data.allowlist);
    });
}

export default function Allowlist() {
  const [items, setItems] = useState<AllowListEntry[]>([]);
  const { state } = useGlobalState();
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/whitelist/`, {
        headers: {
          Authorization: `Bearer ${state.accessToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setItems(response.data.allowlist);
      });
  }, [state.accessToken]);

  const columns: GridColDef[] = [
    {
      field: "ffId",
      headerName: "NeoCargo-ID",
      minWidth: 120,
      maxWidth: 300,
    },
    { 
      field: "mail", 
      headerName: "Email", 
      minWidth: 400, 
      maxWidth: Infinity }
    // {
    //   field: "allowlistgroup",
    //   headerName: "Bieterkreisgruppe",
    //   minWidth: 300,
    //   maxWidth: Infinity,
    // },
  ];

  const rows = items.map((item, index) => {
    return {
      id: index,
      // allowlistgroup: "",
      //allowlistgroup: "Beispielbieterkreis",
      ffId: item.ffId?.value,
      mail: item.username,
    };
  });

  return (
    <>
      <Box sx={{ height: "83vh", width: "100%" }}>
        <DataGrid
          sx={{
            border: "solid",
            borderWidth: 2,
            borderColor: "green",
          }}
          pageSize={10}
          columns={columns}
          rowsPerPageOptions={[5]}
          rows={items && rows}
          checkboxSelection
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          selectionModel={selectionModel}
          disableSelectionOnClick
          components={{
            Toolbar: CustomToolbarComponent,
          }}
          componentsProps={{
            toolbar: { selectionModel, state, setItems, items},
          }}
        />
      </Box>
    </>
  );
}
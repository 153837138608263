import React, { useState, useEffect } from 'react';
import {
  Typography,
} from "@mui/material";

interface Props {
  text?: string | null;
}

function DispatchInfo(props: Props) {
  const [showFullText, setShowFullText] = useState(false);
  const [displayText, setDisplayText] = useState<string | null>(null);

  useEffect(() => {
    if (props.text !== null && props.text !== undefined) {
      setDisplayText(props.text);
    } else {
      setDisplayText(null);
    }
  }, [props.text]);

  const toggleFullText = () => {
    setShowFullText(!showFullText);
  };

  const maxDisplayLength = 100;
  const textToDisplay =
    displayText && displayText.length > maxDisplayLength
      ? showFullText
        ? displayText
        : `${displayText.slice(0, maxDisplayLength)}...`
      : displayText || '';

  return (
    <div>
      {textToDisplay && (
        <div>
          <Typography style={{ fontWeight: "600", display: "inline" }}>
            {" "}
            Dispatch Info:
          </Typography>
          <p style={{ marginLeft: "5px", display: "inline" }}>{textToDisplay}</p>
          {displayText && displayText.length > maxDisplayLength && (
            <button style={{ marginLeft: "5px" }} onClick={toggleFullText}>
              {showFullText ? 'Less Information' : 'More Information'}
            </button>
          )}
        </div>
      )}
    </div>
  );
}

export default DispatchInfo;

import React from 'react';

type RequirementIconProps = {
  requirement: {
    icon: string;
    label: string;
  };
};

const RequirementIcon = ({ requirement }: RequirementIconProps) => {
  return (
    <span className="material-icons" title={requirement.label}>
      {requirement.icon}
    </span>
  );
};

export default RequirementIcon;

import React from "react";
import { Card, Typography, List, ListItem, ListItemText } from "@mui/material";
import Lottie from "react-lottie-player";
import truckLottie from "./../assets/car-loading.json";

function Home() {
  return (
    <Card style={{ padding: "50px", marginLeft: "50px", maxWidth: "800px" }}>
      <Typography variant="h4" gutterBottom>
        Welcome to NeoBid!
      </Typography>
      <Typography variant="body1" paragraph>
        The NeoBid platform facilitates the process of offering and receiving lucrative shipments. 
      </Typography>
      <Typography variant="body1" paragraph>
        To this end, offerers post their available shipments on NeoBid, where tenderers can then participate in a bidding process to get shipments that suit them best.
      </Typography>
      <Typography variant="body1" style={{ fontWeight: "bold", marginBottom: "20px" }}>
        Note: NeoBid is completely free to use for you!
      </Typography>
      <Typography variant="h5" gutterBottom>
        How to use NeoBid as a Tenderer:
      </Typography>
      <List>
        <ListItem>
          <ListItemText
            primary="1.) Navigate to the 'Offered to You' tab to access active bidding processes."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="2.) When interested in a shipment, enter your bid."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="3.) Get notified via mail once you win an offer."
          />
        </ListItem>
      </List>
      <Lottie
        loop
        animationData={truckLottie}
        play
        style={{ width: 300, height: 300, margin: "auto" }}
      />
    </Card>
  );
}

export default Home;

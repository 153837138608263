const requirements = {
  "language": "en",
  "types": [
    {
      "type": "boxTrailer",
      "label": "Box body",
      "inputType": "BOOLEAN",
      // "icon": "box-trailer"
      "icon": "info"
    },
    {
      "type": "cleanedVehicle",
      "label": "Clean",
      "inputType": "BOOLEAN",
      "icon": "cleaning_services"
    },
    {
      "type": "craneLoadable",
      "label": "Crane load",
      "inputType": "BOOLEAN",
      // "icon": "crane-loadable"
      "icon": "info"
    },
    {
      "type": "CUSTOMS_CORD",
      "label": "Customs cord",
      "inputType": "BOOLEAN",
      "icon": "gesture"
    },
    {
      "type": "DIN_EN_12642",
      "label": "EN12642",
      "inputType": "ENUM",
      "icon": "security_update_good"
    },
    {
      "type": "DOCUMENTS_AT_VISIT",
      "label": "Original delivery note",
      "inputType": "BOOLEAN",
      "icon": "description"
    },
    {
      "type": "emptyVehicle",
      "label": "Empty vehicle",
      "inputType": "BOOLEAN",
      // "icon": "empty-vehicle"
      "icon": "info"
    },
    {
      "type": "fireExtinguisher",
      "label": "Fire extinguisher",
      "inputType": "BOOLEAN",
      "icon": "fire_extinguisher"
    },
    {
      "type": "foodstuffs",
      "label": "Foodstuffs",
      "inputType": "BOOLEAN",
      "icon": "restaurant"
    },
    {
      "type": "forkliftDriveIn",
      "label": "Forklift accessible",
      "inputType": "BOOLEAN",
      "icon": "info"
    },
    {
      "type": "handForklift",
      "label": "Hand forklift",
      "inputType": "BOOLEAN",
      "icon": "info"
    },
    {
      "type": "healthCert",
      "label": "Tested, vaccinated, recovered",
      "inputType": "BOOLEAN",
      "icon": "coronavirus"
    },
    {
      "type": "keepDry",
      "label": "Keep dry",
      "inputType": "BOOLEAN",
      "icon": "umbrella"
    },
    {
      "type": "liftingRoof",
      "label": "Lifting roof",
      "inputType": "ENUM",
      "icon": "eject"
    },
    {
      "type": "LOAD_HEIGHT",
      "label": "Load height",
      "inputType": "INTEGER",
      // "icon": "load-height"
      "icon": "info"
    },
    {
      "type": "LOAD_LENGTH",
      "label": "Load length",
      "inputType": "INTEGER",
      "icon": "info"
    },
    {
      "type": "LOAD_WIDTH",
      "label": "Load width",
      "inputType": "INTEGER",
      // "icon": "load-width"
      "icon": "info"
    },
    {
      "type": "mobileForklift",
      "label": "Mobile forklift",
      "inputType": "BOOLEAN",
      // "icon": "mobile-forklift"
      "icon": "info"
    },
    {
      "type": "noTrailer",
      "label": "No trailer",
      "inputType": "BOOLEAN",
      "icon": "info"
    },
    {
      "type": "palletSwap",
      "label": "Pallet swap",
      "inputType": "BOOLEAN",
      "icon": "info"
      //"icon": "pallet-swap"
    },
    {
      "type": "ppeFfp2Mask",
      "label": "PPE FFP2",
      "inputType": "BOOLEAN",
      "icon": "masks"
    },
    {
      "type": "ppeHelmet",
      "label": "PPE helmet",
      "inputType": "BOOLEAN",
      "icon": "info"
    },
    {
      "type": "ppeShoes",
      "label": "PPE shoes",
      "inputType": "ENUM",
      "icon": "info"
    },
    {
      "type": "ppeVest",
      "label": "PPE vest",
      "inputType": "BOOLEAN",
      "icon": "info"
    },
    {
      "type": "reloadingForbidden",
      "label": "No reloading",
      "inputType": "BOOLEAN",
      "icon": "directions_off"
    },
    {
      "type": "sideLoadable",
      "label": "Side load",
      "inputType": "BOOLEAN",
      // "icon": "side-loadable"
      "icon": "info"
    },
    {
      "type": "slidingRoof",
      "label": "Sliding roof",
      "inputType": "BOOLEAN",
      "icon": "info"
    },
    {
      "type": "tailLift",
      "label": "Tail lift",
      "inputType": "BOOLEAN",
      "icon": "info"
      //"icon": "tail-lift"
    },
    {
      "type": "throughLoadable",
      "label": "Through load trailer",
      "inputType": "BOOLEAN",
      "icon": "info"
    },
    {
      "type": "trackingLevel",
      "label": "Tracking",
      "inputType": "ENUM",
      "icon": "location_on"
    },
    {
      "type": "wasteSign",
      "label": "A-Sign",
      "inputType": "BOOLEAN",
      "icon": "info"
    },
    {
      "type": "whitelabelVehicle",
      "label": "Whitelabel",
      "inputType": "BOOLEAN",
      "icon": "info"
    }
  ]
};

export default requirements;

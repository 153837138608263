import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { AuthProvider, TAuthConfig } from "react-oauth2-code-pkce";

const authConfig: TAuthConfig = {
  clientId: `${process.env.REACT_APP_CLIENT_ID}`,
  authorizationEndpoint:
    `${process.env.REACT_APP_AUTH_ENDPOINT}`,
  tokenEndpoint: `${process.env.REACT_APP_TOKEN_ENDPOINT}`,
  redirectUri: `${process.env.REACT_APP_REDIRECT_URI}`,
  scope: "openid",
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthProvider authConfig={authConfig}>
      <App />
    </AuthProvider>
  </React.StrictMode>
);

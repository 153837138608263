import React from "react";
import {
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  Typography,
  Card,
  CardHeader,
  Button,
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import { ExpandLess, ExpandMore, AccessTime } from "@mui/icons-material";
import Bidding from "../../types/Bidding";
import { Bid } from "./Bid/Bid";
import Moment from "react-moment";
import ScaleIcon from "@mui/icons-material/Scale";
import AspectRatio from "@mui/icons-material/AspectRatio";
import Straighten from "@mui/icons-material/Straighten";
import { BidsActive } from "./Bids/BidsActive";
import { BidFinished } from "./Bid/BidFinished";
import { BidsFinished } from "./Bids/BidsFinished";
import { BidsRevoked } from "./Bids/BidsRevoked";
import { useGlobalState } from "../GlobalStateProvider";
import axios from "axios";
import requirements from '../Shipment/Requirements';
import RequirementIcon from '../Shipment/RequirementIcon'
import DispatchInfo from "./DispatchInfo";

export function ShipmentItem(props: {
  item: Bidding;
  view: String;
  setItems: Function;
}) {
  const [open, setOpen] = React.useState(false);

  //toggle Function für die Sendung
  const handleClick = () => {
    setOpen(!open);
  };

  const { state } = useGlobalState();
  const filteredRequirements = props.item?.shipment?.requirements;

  Moment.globalLocale = "de";

  const changeBiddingState = (biddingID: String, status: String) => {
    const data = {
      biddingId: biddingID,
      newStatus: status,
    };
    const statusGet = status === "active" ? "paused" : "active";
    const headers = {
      Authorization: `Bearer ${state.accessToken}`,
      "Content-Type": "application/json",
    };
    axios
      .put(`${process.env.REACT_APP_API_URL}/api/v1/biddings/status`, data, {
        headers: headers,
      })
      .then((response) => {
        if (response.status === 200) {
          axios
            .get(
              `${process.env.REACT_APP_API_URL}/api/v1/biddings/${statusGet}/`,
              {
                headers: {
                  Authorization: `Bearer ${state.accessToken}`,
                  "Content-Type": "application/json",
                },
              }
            )
            .then((getreponse) => props.setItems(getreponse.data));
        }
      });
  };

  return (
    <>
      <List
        key={props.item.id as React.Key}
        sx={{
          width: "100%",
          maxWidth: "100%",
          bgcolor: "#9ad6bd",
          borderRadius: "10px",
          alignItems: "center",
          marginBottom: "15px",
        }}
        component="nav"
      >
        <ListItemButton onClick={handleClick} role="button">
          <Typography sx={{ mr: "20px", fontWeight: "700" }}>
            Shipment:{" "}
          </Typography>
          <ListItemText primary={props.item.shipment.id} />

          {(props.view === "OffersActive" ||
            props.view === "OffersFinished" ||
            props.view === "OffersPaused") && (
              <Typography sx={{ mr: "20px", fontWeight: "700" }}>
              Offered by: {props.item.offererUsername}
            </Typography>
          )}

          {((props.view === "BiddingsActive" ||
            props.view === "BiddingsFinished" ||
            props.view === "BiddingsRevoked") &&
            props.item.bids.length > 0) && (
            <Typography
            sx={{ mr: "20px", fontWeight: "700" }}
            >
              Best Bid: 
              <Typography sx={{ mr: "20px", fontWeight: "250" }}>
                {Bidding.findLowestBid(props.item.bids).value.toString()} {Bidding.findLowestBid(props.item.bids).currency}
                </Typography>
            </Typography>
          )}
          {(props.view === "BiddingsActive" ||
            props.view === "BiddingsFinished" ||
            props.view === "BiddingsRevoked") && (
            <Typography
            sx={{ mr: "20px", fontWeight: "700" }}
            >
              #Bids: 
              <Typography
            sx={{ mr: "20px", fontWeight: "250" }}
            >
              {props.item.bids.length}
            </Typography>
            </Typography>
          )}

          <Straighten />
          <div style={{ marginRight: "30px", marginLeft: "10px" }}>
            {props.item.shipment.totalLoadMeters?.toString()} ldm
          </div>
          <ScaleIcon />

          <div style={{ marginRight: "30px", marginLeft: "10px" }}>
            {props.item.shipment.totalWeight?.toString()} kg
          </div>
          <AspectRatio />
          <div style={{ marginRight: "30px", marginLeft: "10px" }}>
            {props.item.shipment.totalVolume?.toString()} m³
          </div>
          {(props.view === "OffersActive" ||
            props.view === "OffersFinished" ||
            props.view === "OffersPaused") && (
            <Typography
              data-testid="offerView"
              sx={{
                backgroundColor: "#1E90FF",
                color: "white",
                padding: "7px 10px 7px 10px",
                borderRadius: "25px",
                marginRight: "15px",
              }}
            >
              {props.item.bids.length > 0 && (
                <>{`Your Bid: ${props.item.bids[0].value} ${props.item.bids[0].currency}`}</>
              )}
              {props.item.bids.length === 0 && <>Click to place your Bid</>}
            </Typography>
          )}
          <div>
            {props.view === "BiddingsActive" && (
              <Button
                sx={{
                  background: "grey",
                  "&:hover": {
                    background: "#1976d2",
                  },
                  color: "white",
                  fontSize: "small",
                  marginRight: "15px",
                }}
                onClick={() => changeBiddingState(props.item.id, "paused")}
              >
                Pause Bidding
              </Button>
            )}
          </div>
          <div>
            {props.view === "BiddingsRevoked" && (
              <Button
                sx={{
                  background: "grey",
                  "&:hover": {
                    background: "#1976d2",
                  },
                  color: "white",
                  fontSize: "small",
                  marginRight: "15px",
                }}
                onClick={() => changeBiddingState(props.item.id, "active")}
              >
                Unpause Bidding
              </Button>
            )}
          </div>

          <div>
    {props.view === "BiddingsActive" && (
        <Tooltip title="Permanently aborts the bidding. Tenderers will no longer be able to view it. Bidding has to be started again from NeoCargo.">
            <Button
                sx={{
                    background: "#d9534f",
                    "&:hover": {
                        background: "#1976d2",
                    },
                    color: "white",
                    fontSize: "small",
                    marginRight: "15px",
                }}
                onClick={() => changeBiddingState(props.item.id, "closed")}
            >
                <DeleteIcon />
            </Button>
        </Tooltip>
    )}
</div>
<div>
    {props.view === "BiddingsRevoked" && (
        <Tooltip title="Permanently aborts the bidding. Tenderers will no longer be able to view it. Bidding has to be started again from NeoCargo.">
            <Button
                sx={{
                    background: "#d9534f",
                    "&:hover": {
                        background: "#1976d2",
                    },
                    color: "white",
                    fontSize: "small",
                    marginRight: "15px",
                }}
                onClick={() => changeBiddingState(props.item.id, "closed")}
            >
                <DeleteIcon />
            </Button>
        </Tooltip>
    )}
</div>
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <div className="quickinfo" style={{ backgroundColor: "#FFFFFF" }}>
          {" "}
          <Grid container justifyContent="center" style={{ padding: "20px" }}>
            <Grid xs={3} style={{ marginRight: "20px" }} item={true}>
              <Typography style={{ fontWeight: "600" }}>From:</Typography>
              <div>{props.item.shipment.pickup.station.name}</div>
              <div>{`${props.item.shipment.pickup.station.address?.zipcode} ${props.item.shipment.pickup.station.address?.city} ${props.item.shipment.pickup.station.address?.country}`}</div>
              <div>
                <Typography style={{ fontWeight: "600" }}>
                  Pickup time window(s):
                </Typography>
                {props.item.shipment.pickup.allowedTimeWindows.map(
                  (element, index) => (
                    <>
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "small",
                          paddingTop: "10px",
                        }}
                      >
                        <AccessTime
                          style={{
                            width: "15px",
                            height: "15px",
                          }}
                        />{" "}
                        <Moment
                          date={element.startTime}
                          format="DD. MMM,  HH:mm [ - ]"
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "small",
                        }}
                      >
                        {" "}
                        <AccessTime
                          style={{
                            width: "15px",
                            height: "15px",
                          }}
                        />{" "}
                        <Moment
                          date={element.endTime}
                          format="DD. MMM,  HH:mm"
                        />
                      </div>
                    </>
                  )
                )}
              </div>
            </Grid>

            <Grid
              xs={3}
              style={{ marginLeft: "20px", marginRight: "20px" }}
              item={true}
            >
              <Typography style={{ fontWeight: "600" }}>To:</Typography>
              <div>{props.item.shipment.delivery.station.name}</div>
              <div>{`${props.item.shipment.delivery.station.address?.zipcode} ${props.item.shipment.delivery.station.address?.city} ${props.item.shipment.delivery.station.address?.country}`}</div>
              <div>
                <Typography style={{ fontWeight: "600" }}>
                  Delivery time window(s):
                </Typography>
                {props.item.shipment.delivery.allowedTimeWindows.map(
                  (element, index) => (
                    <>
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "small",
                          paddingTop: "10px",
                        }}
                      >
                        <AccessTime
                          style={{
                            width: "15px",
                            height: "15px",
                          }}
                        />
                        <Moment
                          date={element.startTime}
                          format="DD. MMM, HH:mm [ - ]"
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "small",
                        }}
                      >
                        {" "}
                        <AccessTime
                          style={{
                            width: "15px",
                            height: "15px",
                          }}
                        />{" "}
                        <Moment
                          date={element.endTime}
                          format="DD. MMM,  HH:mm"
                        />
                      </div>
                    </>
                  )
                )}
              </div>
            </Grid>
            <Grid xs={3} style={{ marginLeft: "20px" }} item={true}>
              <Typography style={{ fontWeight: "600" }}>
                {" "}
                Requirements:
              </Typography>
              <div
                style={{ display: "flex", flexWrap: "wrap", paddingLeft: "0" }}
              >
                <div>
                  {requirements.types.map((requirement) => (
                    filteredRequirements &&
                    filteredRequirements[requirement.type] ? (
                      <RequirementIcon
                        key={requirement.type}
                        requirement={requirement}
                      />
                    ) : null
                  ))}
                </div>
              </div>
            </Grid>
            <Grid xs={10}  item={true}>
          <div style={{ marginTop: "20px", marginLeft: "20px"}}>
            <DispatchInfo text={props.item.shipment.dispatchInfo?.toString()} />
          </div>
          </Grid>
          </Grid>
          
        </div>

        <Collapse
          sx={{ borderCollapse: "separate" }}
          in={open}
          timeout="auto"
          unmountOnExit
        >
          <Card
            style={{
              marginLeft: "12vh",
              marginRight: "12vh",
              marginTop: "4vh",
              marginBottom: "3vh",
              padding: "0",
              minHeight: "20vh",

              justifyContent: "center",
            }}
          >
            {props.view === "OffersActive" && (
              <div>
                <Bid
                  items={props.item.bids}
                  setItems={props.setItems}
                  biddingID={props.item.id}
                />
              </div>
            )}
            {(props.view === "OffersFinished" ||
              props.view === "OffersPaused") && (
              <div>
                <CardHeader
                  title="Your Bid"
                  data-testid="detailView"
                  style={{
                    backgroundColor: "#3A9B57",
                    color: "white",
                    fontWeight: "700",
                    display: "flex",
                  }}
                />
                <BidFinished
                  items={props.item.bids}
                  biddingID={props.item.id}
                />
              </div>
            )}
            {props.view === "BiddingsActive" && (
              <div>
                <CardHeader
                  title="Bids"
                  style={{
                    backgroundColor: "#3A9B57",
                    color: "white",
                    fontWeight: "700",
                    display: "flex",
                  }}
                />
                <BidsActive items={props.item.bids} setItems={props.setItems} />
              </div>
            )}
            {props.view === "BiddingsFinished" && (
              <div>
                <CardHeader
                  title="Bids"
                  style={{
                    backgroundColor: "#3A9B57",
                    color: "white",
                    fontWeight: "700",
                    display: "flex",
                  }}
                />
                <BidsFinished items={props.item.bids} />
              </div>
            )}
            {props.view === "BiddingsRevoked" && (
              <div>
                <CardHeader
                  title="Bids"
                  style={{
                    backgroundColor: "#3A9B57",
                    color: "white",
                    fontWeight: "700",
                    display: "flex",
                  }}
                />
                <BidsRevoked items={props.item.bids} />
              </div>
            )}
          </Card>
        </Collapse>
      </List>
    </>
  );
}

import Shipment from "./Shipment";
import Bid from "./Bid";

export default class Bidding {
  id!: String;
  offererUsername!: String;
  userId!: Number;
  shipment!: Shipment;
  bids!: Array<Bid>;

  constructor(initializer?: any) {
    if (!initializer) return;
    if (initializer.id) this.id = initializer.id;
    if (initializer.offererUsername) this.offererUsername = initializer.offererUsername;
    if (initializer.userId) this.userId = initializer.userId;
    if (initializer.shipment)
      this.shipment = new Shipment(initializer.shipment);
    if (initializer.bids) this.bids = new Array<Bid>(initializer.bids);
  }

  public static findLowestBid(bids: Bid[]): Bid {
   let min: Number = Number.MAX_SAFE_INTEGER
   let minIndex = 0;

   for(let i=0; i<bids.length; i++) {
    if(bids[i].value < min) {
      min = bids[i].value
      minIndex = i;
    }
   }
   return bids[minIndex]
}
}

export default class AssignedBidTO {
    shipmentIds!: Array<Number>;
    subcontractor!: String;
    priceAmount!: Number;
    currency!: String;
  
    constructor(shipmentIds: Array<Number>, subcontractor: String, priceAmount: Number, currency: String) {
      this.shipmentIds = shipmentIds;
      this.subcontractor = subcontractor;
      this.priceAmount = priceAmount;
      this.currency = currency;
    }
  }